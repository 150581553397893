import {createStore} from 'vuex'
import timing from './modules/timing'
import menu from './modules/menu'

export default createStore({
    modules: {
        timing,
        menu
    }
})
