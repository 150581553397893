import axios from '../axios.config';
// import axios from '../yssaxios';
const http = axios();
export default {
    //获取推荐软件公司列表
    getRecommendList() {
        return http.get(process.env.BASE_URL + 'json/info1.json')
    },
    //获取自荐软件公司列表
    getRecommendSelfList() {
        return http.get(process.env.BASE_URL + 'json/info2.json')
    },
    //获取自荐软件公司列表
    getRecommendDetails() {
        return http.get(process.env.BASE_URL + 'json/detailInfos/info1.json')
    },
    //获取自荐软件公司列表
    getRecommendSelfDetails() {
        return http.get(process.env.BASE_URL + 'json/detailInfos/info2.json')
    }
}
