interface EventType {
    eventName: string,
    params: any,
    callbacks: Array<EventFunc>
}

interface EventFunc {
    (...args: any): void
}

class EventBus {
    private _eventList: Array<EventType> = []

    public $on(eventName: string, callback: EventFunc) {
        const event = this._findEvent(eventName);
        if (event) {
            const func = event.callbacks.find(fn => fn === callback);
            if (!func) {
                event.callbacks.push(callback);
            }
        } else {
            this._addEvent(eventName, [callback], '');
        }
    }

    public $emit(eventName: string, ...params: any) {
        const event = this._findEvent(eventName);
        if (event && event.callbacks.length > 0) {
            event.callbacks.map(func => {
                func(...params);
            })
        }
    }

    private _findEvent(eventName: string) {
        return this._eventList.find(e => e.eventName === eventName);
    }

    private _addEvent(eventName: string, callbacks?: Array<EventFunc>, params?: any): void {
        this._eventList.push({
            eventName: eventName,
            params: params ?? '',
            callbacks: callbacks ?? []
        })
    }
}

export const eventBus = new EventBus();