const state = () => ({
    currentMenu: '',
    menuStatus: 'shrink'
})
// getters
const getters = {}
// actions
const actions = {}
// mutations
const mutations = {
    updateCurrent(state: any, menu: string) {
        state.currentMenu = menu
    },
    updateStatus(state: any, status: string) {
        state.menuStatus = status
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
