<template>
    <div class="header-container">
        <div class="header-title">
            <div class="logo-panel"></div>
            <div class="title">电力行业软件生态圈</div>
        </div>
        <div class="menu">
            <div class="home-1" @click="selectMenu(0)">
                <img v-if="data.selectMenuIndex==0" :src="require('@/assets/images/menu/home_icon_active.svg')" style="float: left; width: 3vh; height: 3vh"/>
                <img v-else :src="require('@/assets/images/menu/home_icon.svg')" style="float: left; width: 3vh; height: 3vh"/>

                <div v-if="data.selectMenuIndex==0" style="color: rgba(44, 104, 255, 1);" class="text">首页</div>
                <div v-else class="text">首页</div>

                <div v-if="data.selectMenuIndex==0" style="width: 100%; height: 10%; background-color: rgba(44, 104, 255, 1); "></div>

            </div>
            <div class="home-2" @click="selectMenu(1)">

                <img v-if="data.selectMenuIndex==1" :src="require('@/assets/images/menu/recommend_icon_active.svg')" style="float: left; width: 3vh; height: 3vh"/>
                <img v-else :src="require('@/assets/images/menu/recommend_icon.svg')" style="float: left; width: 3vh; height: 3vh"/>

                <div v-if="data.selectMenuIndex==1" style="color: rgba(44, 104, 255, 1);" class="text">软件联盟</div>
                <div v-else class="text">软件联盟</div>

                <div v-if="data.selectMenuIndex==1" style="width: 100%; height: 10%; background-color: rgba(44, 104, 255, 1); "></div>

            </div>
            <!-- <div class="home-3" @click="selectMenu(2)">

                <img v-if="data.selectMenuIndex==2" :src="require('@/assets/images/menu/recommend_self_icon_active.svg')" style="float: left; width: 3vh; height: 3vh"/>
                <img v-else :src="require('@/assets/images/menu/recommend_self_icon.svg')" style="float: left; width: 3vh; height: 3vh"/>

                <div v-if="data.selectMenuIndex==2" style="color: rgba(44, 104, 255, 1);" class="text">优秀软件推荐榜盟</div>
                <div v-else class="text">优秀软件推荐榜盟</div>

                <div v-if="data.selectMenuIndex==2" style="width: 100%; height: 10%; background-color: rgba(44, 104, 255, 1); "></div>

            </div> -->
        </div>
    </div>
</template>
<script>
import { reactive } from 'vue';
import { eventBus } from '@/utils/eventBus';
import {useRouter} from 'vue-router'
export default {
    name: "HeaderComponent",
    setup() {
        let router = useRouter();
        const data = reactive ({
            title1: "Electric Power Engineering Software Development Alliance",
            selectMenuIndex: null,
            menus: [
                {
                    text: "首页",
                    img: "home_icon.svg",
                    img1: "home_icon_active.svg"
                },
                {
                    text: "软件联盟",
                    img: "recommend_icon.svg",
                    img1: "recommend_icon_active.svg"
                },
                {
                    text: "优秀软件推荐榜",
                    img: "recommend_self_icon.svg",
                    img1: "recommend_self_icon_active.svg"
                }
            ]
        })
        eventBus.$on('set-menu-index' , val => {
            data.selectMenuIndex = val;
        })
        const selectMenu = (val) => {
            data.selectMenuIndex = val;
            if(router.currentRoute.value.name == 'm_list') {
                if(val == 0) {
                    router.push({
                        name: "m_home",
                        query: {
                            isSkip: false
                        }
                    });

                }else if(val == 1) {
                    router.push({
                        name: "m_introduce"
                    });
                }else{
                    router.push({
                        name: "m_home",
                        query: {
                            isSkip: true
                        }
                    });
                }
            }else {
                if(val == 0) {
                    router.push({
                        name: "m_home",
                        query: {
                            isSkip: false
                        }
                    });
                }else if(val == 1) {
                    router.push({
                        name: "m_introduce"
                    });
                }else{
                    router.push({
                        name: "m_home",
                        query: {
                            isSkip: true
                        }
                    });
                }
            }
        }
        return {
            data,
            selectMenu
        }
    }
}
</script>
<style lang="scss" scoped>
@import "~@/variable.scss";
 .header-container {
    position: absolute;
    width: 100%;
    height: 10%;
    top: 0;
    left: 0;
    padding-top: 2vh;
    padding-left: 2vh;
    .header-title {
        width: 50%;
        height: 45%;
        float: left;
        .logo-panel {
            width: 3vh;
            height: 3vh;
            margin-top: 0.6%;
            background: {
                image: url("@/assets/images/logo.png");
                repeat: no-repeat;
                size: 100% 100%;
            }
            float: left;
            margin-left: 2%;
        }
        .title {
            width: 82.7%;
            height: 100%;
            color: #000;
            float: left;
            padding-top: 0.6vh;
            padding-left: 0.6vh;
            margin-left: .6%;
            text-align: left;
            font-weight: 600;
            font-size: 1.6vh;
            letter-spacing: 0.1vh;
            overflow: hidden;
            text-overflow: hidden;
            white-space: nowrap;
        }
    }
    .menu {
        width: 50%;
        height: 55%;
        float: left;
        .home-1 {
            cursor: pointer;
            width: 30%;
            height: 100%;
            float: left;
            .text {
                font-size: 1.4vh;
                float: left;
                text-align: center;
                line-height: 3.1vh;
            }
        }
        .home-2 {
            cursor: pointer;
            width: 70%;
            height: 100%;
            float: left;
            .text {
                font-size: 1.4vh;
                float: left;
                text-align: center;
                line-height: 3.1vh;
            }
        }
        .home-3 {
            cursor: pointer;
            width: 35%;
            height: 100%;
            float: left;
            .text {
                width: 66%;
                font-size: 1.4vh;
                float: left;
                text-align: center;
                line-height: 3.1vh;
            }
        }
    }
 }
</style>