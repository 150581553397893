import axios from '../axios.config';
import chat from '../axios-chat.config';
const http = axios();
const chatHttp = chat();
export default {
    //获取历史对话消息
    getMessageInfos() {
        return http.get(process.env.BASE_URL + 'json/message/info.json')
    },

    //与知识库对话
    knowledgeBaseChat(param: any) {
        return chatHttp.post(`/chat/knowledge_base_chat`,param)
    }
}
