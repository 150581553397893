const state = () => ({
    timing: ''
})
// getters
const getters = {}
// actions
const actions = {}
// mutations
const mutations = {
    updateTiming(state: any, timing: string) {
        state.timing = timing
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
