<template>
    <div class="page-container">
        <header-component v-if="!data.isPhone" class="header"></header-component>
        <m-header-component v-else class="header"></m-header-component>
        <div class="home" :style="{'height': !data.isPhone ? '83%':'90%', 'top': !data.isPhone ? '8.1%' : '8.1%'}">
            <router-view />
        </div>
        <div class="bottom" v-if="!data.isPhone">
            <bottom-component class="bottom-panel"></bottom-component>
        </div>
    </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { eventBus } from "@/utils/eventBus";
import { useRouter, useRoute } from 'vue-router'
import HeaderComponent from "@/components/common/HeaderComponent.vue"
import MHeaderComponent from "@/components/common/M_HeaderComponent.vue"
import BottomComponent from '@/components/common/BottomComponent.vue'
export default {
    name: "LayoutView",
    components: { HeaderComponent, BottomComponent, MHeaderComponent },
    setup() {
        let router = useRouter();
        const data = reactive({
            info: "",
            isPhone: false,
        })
        onMounted(() => {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            let pathName = router.currentRoute.value.name;
            if (flag) {
            	data.isPhone = true;
                if(pathName== "home" || pathName== "m_home"){
                    router.push({
                        name: 'm_home'
                    })
                }
                if(pathName== "list" || pathName== "m_list"){
                    router.push({
                        name: 'm_list'
                    })
                }
                if(pathName== "recommendDetails" || pathName== "m_recommendDetails"){
                    let query = router.currentRoute.value.query;
                    router.push({
                        name: 'm_recommendDetails',
                        query: query
                    })
                }
                if(pathName== "preview" || pathName== "m_preview"){
                    let query = router.currentRoute.value.query;
                    router.push({
                        name: 'm_preview',
                        query: query
                    })
                }
            } else {
            	data.isPhone = false;
                if(pathName== "home" || pathName== "m_home"){
                    router.push({
                        name: 'home'
                    })
                }
                if(pathName== "list" || pathName== "m_list"){
                    router.push({
                        name: 'list'
                    })
                }
                if(pathName== "recommendDetails" || pathName== "m_recommendDetails"){
                    let query = router.currentRoute.value.query;
                    router.push({
                        name: 'recommendDetails',
                        query: query
                    })
                }
                if(pathName== "preview" || pathName== "m_preview"){
                    let query = router.currentRoute.value.query;
                    router.push({
                        name: 'preview',
                        query: query
                    })
                }
            }
        })
        return {
            data
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/variable.scss";

.page-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow-y: hidden;

    .header {
        position: absolute;
        width: 100%;
        height: 8.1%;
        left: 0;
        top: 0;
        overflow-y: hidden;
    }

    .home {
        position: absolute;
        width: 100%;
        left: 0;
        overflow-y: auto;
        background-color: #f5f7ff;

    }

    .bottom {
        width: 100%;
        height: 8.8vh;
        bottom: 0;
        position: absolute;

        .bottom-panel {
            width: 100%;
            height: 8.8vh;
        }
    }
}
</style>