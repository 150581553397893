<template>
    <div class="header-container">
        <div class="logo-panel"></div>
        <div class="title">电力行业软件生态圈</div>
        <div class="menu">
            <div class="home-1" v-for="(item, index) in data.menus" :key="index" @click="selectMenu(index)" :style="{width: index==0 ? '15%' : index==1 ? '20%' : index==2 ? '25%' : '20%'}">
                <img v-if="data.selectMenuIndex==index" :src="require('@/assets/images/menu/'+item.img1)" style="float: left; width: 3vh; height: 3vh"/>
                <img v-else :src="require('@/assets/images/menu/'+item.img)" style="float: left; width: 3vh; height: 3vh"/>
                <div v-if="data.selectMenuIndex==index" style="color: rgba(44, 104, 255, 1);" class="text">{{item.text}}</div>
                <div v-else class="text">{{item.text}}</div>
                <div v-if="data.selectMenuIndex==index" :style="{ width: index==0 ? '65%' : index==1 ? '70%' : index==2 ? '81%' : '70%', height: '10%', backgroundColor: 'rgba(44, 104, 255, 1)' }"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive } from 'vue';
import { eventBus } from '@/utils/eventBus';
import {useRouter} from 'vue-router'
export default {
    name: "HeaderComponent",
    setup() {
        let router = useRouter();
        const data = reactive ({
            title1: "Electric Power Engineering Software Development Alliance",
            selectMenuIndex: null,
            menus: [
                {
                    text: "首页",
                    img: "home_icon.svg",
                    img1: "home_icon_active.svg"
                },
                {
                    text: "软件联盟",
                    img: "recommend_icon.svg",
                    img1: "recommend_icon_active.svg"
                },
                {
                    text: "优秀软件推荐榜",
                    img: "recommend_self_icon.svg",
                    img1: "recommend_self_icon_active.svg"
                },
                {
                    text: "新闻资讯",
                    img: "icon-4.png",
                    img1: "icon-5.png"
                }
            ]
        })
        eventBus.$on('set-menu-index' , val => {
            data.selectMenuIndex = val;
        })
        const selectMenu = (val) => {
            data.selectMenuIndex = val;
            
            if(router.currentRoute.value.name == 'list') {
                if(val == 0) {
                    router.push({
                        path: "home",
                        query: {
                            isSkip: false
                        }
                    });
                }else if(val == 1) {
                    router.push({
                        name: "introduce"
                    });
                }else if(val == 2){
                    router.push({
                        path: "home",
                        query: {
                            isSkip: true
                        }
                    });
                }else{
                    if(router.currentRoute.value.path.indexOf("main") > -1) {
                        router.push({
                            path: "news"
                        });
                    }else{
                        router.push({
                            path: "main/news"
                        });
                    }
                }
            }else {
                if(val == 0) {
                    router.push({
                        path: "home",
                        query: {
                            isSkip: false
                        }
                    });
                }else if(val == 1) {
                    router.push({
                        name: "introduce"
                    });
                }else if(val == 2){
                    router.push({
                        path: "home",
                        query: {
                            isSkip: true
                        }
                    });
                }else{
                    if(router.currentRoute.value.path.indexOf("main") > -1) {
                        router.push({
                            path: "news"
                        });
                    }else{
                        router.push({
                            path: "main/news"
                        });
                    }
                }
            }
        }
        return {
            data,
            selectMenu
        }
    }
}
</script>
<style lang="scss" scoped>
@import "~@/variable.scss";
 .header-container {
    position: absolute;
    width: 100%;
    height: 10%;
    top: 0;
    left: 0;
    padding: 1vh 4vh;
    .logo-panel {
        width: 3vh;
        height: 3vh;
        margin-top: 0.6%;
        background: {
            image: url("@/assets/images/logo.png");
            repeat: no-repeat;
            size: 100% 100%;
        }
        float: left;
        margin-left: 22%;
    }
    .title {
        width: 18.7%;
        height: 45.4%;
        color: #000;
        float: left;
        margin-top: 1vh;
        margin-left: .6%;
        text-align: left;
        font-weight: 600;
        font-size: 2vh;
        letter-spacing: 0.1vh;
        overflow: hidden;
        text-overflow: hidden;
        white-space: nowrap;
    }
    .menu {
        width: 70vh;
        height: 64.4%;
        float: left;
        margin-left: 4%;
        margin-top: .4%;
        .home-1 {
            cursor: pointer;
            width: 23%;
            height: 100%;
            float: left;
            .text {
                width: 100%;
                font-size: 1.5vh;
                text-align: left;
                line-height: 3.1vh;
            }
        }
    }
 }
</style>