<template>
    <div class="home-container">
        <div class="first-panel">
            <div class="text-panel">
                <p class="text-1">为电力设计软件创新</p>
                <p class="text-2">发展提供良好的</p>
                <p class="text-3">生态环境</p>
                <p class="text-4">推进电力工程软件的创新发展</p>
            </div>
        </div>
        <div class="second-panel">
            <div class="header-1">
                <p class="text-5">优秀软件推荐榜</p>
                <p class="text-6">宣传优秀的电力设计软件，推进整个行业健康持续发展</p>
            </div>
            <div class="second-content">
                <div class="content-panel" @click="goToLink(item)" v-for="(item, index) in data.list" :key=index>
                    <div class="content-header" :style="{ 'border-bottom' : index%2==0 ? '1px rgba(42, 43, 52, 0.1) solid':'none'}">
                        <div class="icon">
                            <img style="width: 100%; height: 100%" :src="require('@/assets/images/mobile/'+item.icon)" />
                        </div>
                        <div class="content-text">
                            <p class="text-7">{{item.title}}</p>
                            <p class="text-8">{{item.text}}</p>
                        </div>
                        <div class="header-icon">
                            <img style="width: 100%; height: 100%" :src="require('@/assets/images/mobile/'+item.icon1)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fourthly-panel">
            <div class="header-fourthly-panel">
                <p class="text-7">联系我们</p>
                <p class="text-8">有什么问题随时联系我们</p>
            </div>
            <div class="content-fourthly-panel">
                <div class="text-9">
                    <img src="@/assets/images/mobile/icon-9.png" style="float: left; width: 1.7vh; height: 1.7vh" />
                    <p class="text-10">运营单位名称:</p><p class="text-15">上海叁仟界数字科技有限公司</p>
                </div>
                <div class="text-11">
                    <img src="@/assets/images/mobile/icon-10.png" style="float: left; width: 1.7vh; height: 1.7vh" />
                    <p class="text-12">联系人电话:</p><p class="text-16">19946037026</p>
                </div>
                <div class="text-13">
                    <img src="@/assets/images/mobile/icon-11.png" style="float: left; width: 1.7vh; height: 1.7vh" />
                    <p class="text-14">沪公网安备:</p><p class="text-17">31011302007798号</p>
                </div>
                <div class="text-13">
                    <img src="@/assets/images/mobile/icon-11.png" style="float: left; width: 1.7vh; height: 1.7vh" />
                    <p class="text-14">沪ICP备:</p><p class="text-17">2024079066号</p>
                </div>
            </div>
        </div>
        <div class="ai-panel" @click="goToAIPage">
        </div>
    </div>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'
import { Right } from '@element-plus/icons-vue'
import { eventBus } from '@/utils/eventBus';
import {useRouter} from 'vue-router'
export default {
    setup() {
        let router = useRouter();
        const data = reactive({
            list: [
                {
                    icon: "icon-6.png",
                    title: "设计院软件产品推荐榜",
                    text: "整个清单是本联盟推荐的设计软件",
                    icon1: "icon-3.png",
                    query: {
                        isShow: true
                    } 
                },
                {
                    icon: "icon-7.png",
                    title: "软件公司产品推荐榜",
                    text: "整个清单是本联盟推荐的软件",
                    icon1: "icon-4.png",
                    query: {
                        isShow: false
                    } 
                }
            ]
        })
        const goToLink = (item) => {
            eventBus.$emit('set-menu-index',null)
            router.push({
                name: 'm_list',
                query: item.query
            })
        }
        const goToAIPage = () => {
            //打开AI聊天界面
        }
        onMounted(() => {
            //统计访问
            var _hmt = _hmt || [];
            (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?7007d5ccaa434da50968b929ac8a7548";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
            })();
        })
        return {
            data,
            goToLink,
            goToAIPage
        }
    }
}
</script>
<style lang="scss" scoped>
.home-container {
    top: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: {
        image: url("@/assets/images/mobile/bg.png");
        repeat: no-repeat;
        size: 100% 100%;
    };
    .ai-panel {
        width: 7vh;
        height: 10vh;
        right: 3%;
        top: 30%;
        position: absolute;
        background: {
            image: url("@/assets/images/mobile/icon-14.png");
            repeat: no-repeat;
            size: 100% 100%;
        }
        animation: bounce 2s infinite;
    }
    @keyframes bounce {
        0%, 100% {
            transform:translateY(0)
        };
        50% {
            transform: translateY(-10px)
        }
    }
    .first-panel {
        width: 100%;
        height: 27.4vh;
        background: {
            image: url("@/assets/images/mobile/icon-1.png");
            repeat: no-repeat;
            size: 100% 100%;
        }
        .text-panel {
            width: 21vh;
            height: 21vh;
            margin-left: 7vh;
            padding-top: 7vh;
            letter-spacing: 00.1vh;
            .text-1 {
                width: 100%;
                height: 3vh;
                font-size: 2.2vh;
                font-family: 'Alibaba PuHuiTi 2.0';
                font-weight: 600;
                text-align: left;
            }
            .text-2 {
                width: 100%;
                height: 3vh;
                font-size: 2.2vh;
                font-family: 'Alibaba PuHuiTi 2.0';
                font-weight: 600;
                text-align: left;
            }
            .text-3 {
                width: 45%;
                height: 3vh;
                font-size: 2.2vh;
                font-family: 'Alibaba PuHuiTi 2.0';
                font-weight: 600;
                text-align: left;
                background: linear-gradient(89.92deg, #0C4DF5 46.38%, #0CC2F5 81.25%),
                linear-gradient(0deg, #202F44, #202F44);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .text-4 {
                width: 100%;
                height: 2vh;
                font-size: 1.3vh;
                font-family: 'Alibaba PuHuiTi 2.0';
                text-align: left;
                color: rgba(84, 97, 117, 1);
            }
        }
    }
    .second-panel {
        width: 100%;
        height: 29vh;
        margin-top: 2vh;
        .header-1 {
            width: 100%;
            height: 9vh;
            letter-spacing: 0.1vh;
            .text-5 {
                font-size: 2.2vh;
                font-weight: 600;
                width: 100%;
                height: 4vh;
                padding-top: 1vh;
                text-align: center;
            }
            .text-6 {
                font-size: 1.4vh;
                width: 100%;
                height: 4vh;
                padding-top: 1vh;
                text-align: center;
                color: rgba(84, 97, 117, 0.65);
            }
        }
        .second-content {
            width: 90%;
            height: 20vh;
            background-color: #ffffff;
            border-radius: 10px;
            margin-left: 5%;
            .content-panel {
                width: 100%;
                height: 9.4vh;
                margin-top: 0.6vh;
                .content-header {
                    width: 100%;
                    height: 100%;
                    padding-left: 2vh;
                    .icon {
                        width: 5vh;
                        height: 5vh;
                        float: left;
                        margin-top: 3vh;
                    }
                    .content-text {
                        width: 20vh;
                        height: 5vh;
                        padding-left: 1vh;
                        letter-spacing: 0.1vh;
                        margin-top: 3vh;
                        text-align: left;
                        float: left;
                        .text-7 {
                            width: 100%;
                            height: 3vh;
                            color: #000;
                            font-size: 1.8vh;
                            font-weight: 600;
                        }
                        .text-8 {
                            width: 100%;
                            height: 2vh;
                            color: rgba(84, 97, 117, 0.6);
                            font-size: 1.2vh;
                        }
                    }
                    .header-icon {
                        width: 10vh;
                        height: 10vh;
                        float: left;
                        margin-left: 4vh;
                    }
                }
            }
            .car-content {
                width: 100%;
                height: 16vh;
                .content-bottom {
                    width: 100%;
                    height: 40%;
                    .link-panel {
                        width: 100%;
                        height: 100%;
                        .span {
                            width: 10vh;
                            height: 3vh;
                            color: rgba(44, 104, 255, 1);
                            font-size: 1.4vh;
                            font-weight: 600;
                            margin-left: 15vh;
                            margin-top: 1vh;
                            text-decoration: none;
                            letter-spacing: 0.1vh;
                            float: left;
                            .link-name {
                                width: 8vh;
                                height: 3vh;
                                float: left;
                            }
                            .link-icon {
                                width: 2vh;
                                height: 3vh;
                                float: left;
                            }
                        }
                    }
                }
            }
        }
    }
    .fourthly-panel {
        width: 90%;
        margin-left: 5%;
        height: 17vh;
        letter-spacing: 0.1vh;
        margin-top: 4vh;
        .header-fourthly-panel {
            width: 100%;
            height: 5vh;
            .text-7 {
                height: 3vh;
                width: 100%;
                font-size: 1.8vh;
                font-weight: 600;
                font-family: 'Alibaba PuHuiTi 2.0';
            }
            .text-8 {
                height: 2vh;
                width: 100%;
                font-size: 1.4vh;
                color: rgba(84, 97, 117, 0.65);
                font-family: 'Alibaba PuHuiTi 2.0';
            }
        }
        .content-fourthly-panel {
            width: 100%;
            height: 15vh;
            padding-left: 3vh;
            padding-top: 1vh;
            color: rgba(32, 47, 68, 1);
            border-radius: 10px;
            background: #fff;
            text-align: left;
            letter-spacing: 0.2vh;
            .text-9 {
                padding-top: 1vh;
                height: 3vh;
                width: 100%;
                font-size: 1.4vh;
                font-family: 'Alibaba PuHuiTi 2.0';
                .text-10 {
                    width: 11vh;
                    height: 100%;
                    margin-left: 1vh;
                    float: left;
                }
                .text-15 {
                    width: 23vh;
                    height: 100%;
                    float: left;
                }
            }
            .text-11 {
                height: 3vh;
                width: 100%;
                font-size: 1.4vh;
                padding-top: 1vh;
                font-family: 'Alibaba PuHuiTi 2.0';
                .text-12 {
                    width: 11vh;
                    height: 100%;
                    margin-left: 1vh;
                    float: left;
                }
                .text-16 {
                    width: 23vh;
                    height: 100%;
                    float: left;
                }
            }
            .text-13 {
                height: 3vh;
                width: 100%;
                font-size: 1.4vh;
                padding-top: 1vh;
                font-family: 'Alibaba PuHuiTi 2.0';
                .text-14 {
                    width: 11vh;
                    height: 100%;
                    margin-left: 1vh;
                    float: left;
                }
                .text-17 {
                    width: 23vh;
                    height: 100%;
                    float: left;
                }
            }
        }
    }
}
</style>
<style lang="scss">
    .second-content {
        .el-carousel__arrow {
            display: none !important;
        }
        :deep(.el-carousel__arrow:hover) {
            display: none !important;
        }
        .el-carousel__button {
            background-color: #000;
            width: 1vh;
            height: 1vh;
            border-radius: 50%;
            margin-top: 1vh;
        }
    }
</style>