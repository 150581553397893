import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import vant from 'vant'
// import api from './plugins/api';
import '@/global.scss'
import 'animate.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueSeamlessScroll from "vue-seamless-scroll/src";
// import  pptx  from './PPTXjs-v.1.21.00/css/pptxjs.css';
// import  nvD3Min  from './PPTXjs-v.1.21.00/css/nv.d3.min.css';
// import  jquery  from './PPTXjs-v.1.21.00/js/jquery-1.11.3.min.js';
// import  jszip  from './PPTXjs-v.1.21.00/js/jszip.min.js';
// import  filereader  from './PPTXjs-v.1.21.00/js/filereader.js';
// import  d3Min  from './PPTXjs-v.1.21.00/js/d3.min.js';
// import  nvD3MinJs  from './PPTXjs-v.1.21.00/js/nv.d3.min.js';
// import  pptxjs  from './PPTXjs-v.1.21.00/js/pptxjs.js';
// import  divs2slides  from './PPTXjs-v.1.21.00/js/divs2slides.js';
// import  jqueryFullscreenMin  from './PPTXjs-v.1.21.00/js/jquery.fullscreen-min.js';

createApp(App).use(store).use(router)
.use(VueSeamlessScroll).use(ElementPlus).mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
createApp(App).component(key, component)
}
