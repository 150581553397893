<template>
    <div class="container-panel">
        <div class="home-container" id="anchor1">
            <div class="content-1">
                <p class="content-text-1">为电力设计软件创新发展</p>
                <p class="content-text-2">提供良好的</p><p class="content-text-3">生态环境</p>
                <p class="content-text-4">推进电力工程软件的创新发展</p>
            </div>
        </div>
        <div class="ai-panel" @click="goToAIPage">
        </div>
        <div class="software-container" id="anchor2">
            <div class="content">
                <div class="header">
                    <div class="title-1">优秀软件推荐</div>
                    <div class="title-2">宣传优秀的电力设计软件，推进整个行业健康持续发展</div>
                </div>
                <div class="content-panel">
                    <div class="panel-1" @click="goList(true)">
                        <div class="header-1">
                            <div class="header-1-1"></div>
                            <div class="header-1-2">
                                <p class="header-1-2-1">设计院软件产品推荐榜</p>
                                <p class="header-1-2-2">整个清单是本联盟推荐的软件</p>
                            </div>
                            <div class="header-1-3"></div>
                        </div>
                    </div>
                    <div class="panel-2" @click="goList(false)">
                        <div class="header-2">
                            <div class="header-2-1"></div>
                            <div class="header-2-2">
                                <p class="header-2-2-1">软件公司产品推荐榜</p>
                                <p class="header-2-2-2">整个清单是本联盟自荐的软件</p>
                            </div>
                            <div class="header-2-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, onMounted, computed, watch } from "vue";
import api from '@/plugins/api/main';
import { eventBus } from '@/utils/eventBus';
import {useRouter} from 'vue-router'
export default {
    name: "HomeView",
    setup() {
        let router = useRouter();
        watch(
            () => router.currentRoute.value.query.isSkip,
            (newVal)=>{
                if(newVal == "true") {
                    window.location.href="#anchor2";
                }else if(newVal == "false") {
                    window.location.href="#anchor1";
                }
            }
        )
        const data = reactive({
            inputText: "",
            recommendList: [],
            recommendSelfList: [],
            currentList1: [],
            currentList2: [],
        })
        onMounted(() => {
            //统计访问
            var _hmt = _hmt || [];
            (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?7007d5ccaa434da50968b929ac8a7548";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
            })();
            let val = router.currentRoute.value;
            if(val && val.query){
                if(val.query.isSkip == "true") {
                    window.location.href="#anchor2";
                }else if(val.query.isSkip == "false") {
                    window.location.href="#anchor1";
                }
            }
            getRecommendList().then( resp => {
                data.recommendList = resp.data;
                if(data.recommendList.length>3) {
                    data.currentList1.push(data.recommendList[0]);
                    data.currentList1.push(data.recommendList[1]);
                    data.currentList1.push(data.recommendList[2]);
                }else {
                    Object.assign(data.currentList1, data.recommendList)
                }
            });
            getRecommendSelfList().then( resp => {
                data.recommendSelfList = resp.data;
                if(data.recommendSelfList.length>3) {
                    data.currentList2.push(data.recommendSelfList[0]);
                    data.currentList2.push(data.recommendSelfList[1]);
                    data.currentList2.push(data.recommendSelfList[2]);
                }else {
                    Object.assign(data.currentList2, data.recommendSelfList)
                }
            });
        })
        const skipDetail1=(val) => {
            //软件推荐
            router.push({
                path: "/main/recommendDetails",
                query: {
                    id: val.id,
                    isShow: true
                }
            });

        }
        const skipDetail2=(val) => {
            //软件自荐
            router.push({
                path: "/main/recommendDetails",
                query: {
                    id: val.id,
                    isShow: false
                }
            });
        }
        const goList = (val) => {
            eventBus.$emit('set-menu-index',null)
            router.push({
                path: "/main/list",
                query: {
                    isShow: val
                }
            });
        }
        return {
            data,
            skipDetail1,
            skipDetail2,
            goList
        }
    }
}
async function getRecommendList() {
    return await api.home.getRecommendList();
}
async function getRecommendSelfList() {
    return await api.home.getRecommendSelfList();
}
</script>

<style lang="scss" scoped>
@import "~@/variable.scss";
.container-panel {
    width: 100%;
    height: 93%;
    position: absolute;
    overflow-y: auto;
    .home-container {
        width: 100%;
        height: 83.6vh;
        background: {
            image: url("@/assets/images/bg-1.jpg");
            repeat: no-repeat;
            size: contain;
        };
        overflow-y: hidden;
        .content-1 {
            width: 50%;
            height: 33.1%;
            margin-top: 13.5%;
            margin-left: 19.3%;
            font-family: 'PingFang SC';
            .content-text-1 {
                width: 57vh;
                height: 7vh;
                color: rgba(34, 47, 68, 1);
                font-size: 5vh;
                font-weight: bold;
                text-align: left;
                letter-spacing: 0.1vh;
            }
            .content-text-2 {
                width: 26vh;
                height: 7vh;
                color: rgba(34, 47, 68, 1);
                font-size: 5vh;
                font-weight: bold;
                text-align: left;
                float: left;
                letter-spacing: 0.1vh;

            }
            .content-text-3 {
                width: 22vh;
                height: 7vh;
                font-size: 5vh;
                font-weight: bold;
                text-align: left;
                margin-left: 25vh;
                background: linear-gradient(89.92deg, #0C4DF5 46.38%, #0CC2F5 81.25%),
                linear-gradient(0deg, #202F44, #202F44);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .content-text-4 {
                width: 28vh;
                height: 3vh;
                color: rgba(84, 97, 117, 1);
                font-size: 2vh;
                text-align: left;
                letter-spacing: 0.1vh;
            }
        }
    }
    .software-container {
        width: 100%;
        height: 40vh;
        background-color: rgba($color: rgb(245, 247, 255), $alpha: 1.0);
        padding-top: 3%;
        .content {
            width: 62.9%;
            height: 34vh;
            margin-left: 37vh;
            .header {
                width: 100%;
                height: 10vh;
                .title-1 {
                    width: 100%;
                    height: 5vh;
                    text-align: center;
                    color: #000;
                    font-size: 3vh;
                    font-weight: bold;
                    letter-spacing: 0.2vh;
                }
                .title-2 {
                    width: 100%;
                    height: 5vh;
                    text-align: center;
                    color: rgba(84, 97, 117, 1);
                    font-size: 1.6vh;
                    letter-spacing: 0.1vh;
                }
            }
            .content-panel {
                width: 100%;
                height: 10vh;
                .panel-1 {
                    cursor: pointer;
                    width: 49%;
                    height: 100%;
                    background-color: #fff;
                    border-radius: 20px;
                    float: left;
                    .header-1 {
                        width: 100%;
                        height: 17%;
                        .header-1-1 {
                            width: 4vh;
                            height: 4vh;
                            float: left;
                            margin-top: 3vh;
                            margin-left: 2vh;
                            background: {
                                image: url("@/assets/images/icon-44.png");
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        .header-1-2 {
                            width: 36vh;
                            height: 7vh;
                            float: left;
                            margin-top: 2vh;
                            text-align: left;
                            padding-left: 2vh;
                            .header-1-2-1 {
                                font-family: 'Alibaba PuHuiTi 2.0';
                                font-size: 3vh;
                                font-weight: 600;
                                color: #000;
                            }
                            .header-1-2-2 {
                                font-family: 'Alibaba PuHuiTi 2.0';
                                font-size: 1.2vh;
                                font-weight: 600;
                                color: rgba(84, 97, 117, 0.6);
                            }
                        }
                        .header-1-3 {
                            width: 13vh;
                            height: 10vh;
                            margin-left: 6vh;
                            float: left;
                            background: {
                                image: url("@/assets/images/icon-4.png");
                                repeat: no-repeat;
                                size: 100% 133%;
                                position: 100% 50%;
                            }
                        }
                    }
                    .content-1 {
                        width: 100%;
                        height: 70%;
                        padding: 1vh 2vh;
                        .software-item {
                            width: 100%;
                            height: 30%;
                            border-bottom: 1px rgba($color: rgb(42, 43, 52), $alpha: .1) solid;
                            .text-item {
                                width: 68%;
                                height: 100%;
                                float: left;
                                text-align: left;
                                padding-left: 2%;
                                .software-name {
                                    width: 100%;
                                    height: 3vh;
                                    color: #000;
                                    font-size: 1.3vh;
                                    letter-spacing: 0.1vh;
                                    font-weight: 600;
                                    margin-top: 5vh; 
                                };
                                .company-name {
                                    width: 100%;
                                    height: 50%;
                                    color: #000;
                                    font-size: 1.2vh;
                                    letter-spacing: 0.1vh;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                    .bottom-1 {
                        width: 100%;
                        height: 16%;
                        padding: 5% 42%;
                        .text-1 {
                            color: rgba(43, 128, 255, 1);
                            font-size: 1.6vh;
                            float: left;
                            text-align: center;
                            margin-top: 5%;
                            margin-left: 5%;
                            cursor: pointer;
                        }
                    }
                }
                .panel-2 {
                    cursor: pointer;
                    width: 49%;
                    height: 100%;
                    background-color: #fff;
                    border-radius: 20px;
                    margin-left: 2%;
                    float: left;
                    .header-2 {
                        width: 100%;
                        height: 17%;
                        .header-2-1 {
                            width: 4vh;
                            height: 4vh;
                            float: left;
                            margin-top: 3vh;
                            margin-left: 2vh;
                            background: {
                                image: url("@/assets/images/icon-45.png");
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        .header-2-2 {
                            width: 36vh;
                            text-align: left;
                            padding-left: 2vh;
                            height: 7vh;
                            float: left;
                            margin-top: 2vh;
                            .header-2-2-1 {
                                font-family: 'Alibaba PuHuiTi 2.0';
                                font-size: 3vh;
                                font-weight: 600;
                                color: #000;
                            }
                            .header-2-2-2 {
                                font-family: 'Alibaba PuHuiTi 2.0';
                                font-size: 1.2vh;
                                font-weight: 600;
                                color: rgba(84, 97, 117, 0.6);
                            }
                        }
                        .header-2-3 {
                            width: 13vh;
                            height: 10vh;
                            margin-left:6vh;
                            float: left;
                            background: {
                                image: url("@/assets/images/icon-7.png");
                                repeat: no-repeat;
                                size: 100% 133%;
                                position: 100% 50%;
                            }
                        }
                    }
                    .content-2 {
                        width: 100%;
                        height: 70%;
                        padding: 1vh 2vh;
                        .software-item {
                            width: 100%;
                            height: 30%;
                            border-bottom: 1px rgba($color: rgb(42, 43, 52), $alpha: .1) solid;
                            .text-item {
                                width: 68%;
                                height: 100%;
                                float: left;
                                text-align: left;
                                padding-left: 2%;
                                .software-name {
                                    width: 100%;
                                    height: 3vh;
                                    color: #000;
                                    font-size: 1.3vh;
                                    letter-spacing: 0.1vh;
                                    font-weight: 600;
                                    margin-top: 5vh; 
                                };
                                .company-name {
                                    width: 100%;
                                    height: 50%;
                                    color: #000;
                                    font-size: 1.2vh;
                                    letter-spacing: 0.1vh;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                    .bottom-2 {
                        width: 100%;
                        height: 16%;
                        padding: 5% 42%;
                        .text-1 {
                            color: rgba(43, 128, 255, 1);
                            font-size: 1.6vh;
                            float: left;
                            text-align: center;
                            margin-top: 5%;
                            margin-left: 5%;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .bottom-panel {
        width: 100%;
        height: 8.8vh;
        margin-top: -16.9vh;
    }
}
</style>
<style lang="scss" scoped>
textarea {
    min-height: 5vh;
}
</style>